import React from 'react';

import { Link } from 'gatsby';


const indexStyle = {
    padding: 0,
    margin:0,
}

const IndexPage = () =>
    <main style={indexStyle}>
        <p>Typescript Test</p>

        <Link to="/landing">Landing Page</Link>
        <Link to="/about"> About Page </Link>
        
    </main>
;

export default IndexPage;